
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import ContentCard from '../components/ContentCard';
import StatCard from '../components/StatCard';
import Lambda from 'aws-sdk/clients/lambda';

import awsConfig from '../aws-exports';
Amplify.configure(awsConfig);
/**
 * @returns 
 */
export function BuildingDetails() {

    const location = useLocation();
    const [building, setBuilding] = useState(JSON.parse(location.state))
    const [elevators, setElevators] = useState([]);
    const [userConnected, setUserConnected] = useState([]);
    const [userGroup, setUserGroup] = useState([]);
    const [totalElevators, setTotalElevators] = useState(0);
    const [totalActiveElevators, setTotaActiveElevators] = useState(0);
    const [totalWarnings, setTotalWarnings] = useState(0);
    const [totalAlarms, setTotalAlarms] = useState(0);
    // const [stats, setStatusClass] = useState(0);

    const publicAssetsBaseUrl = "https://public-assets-tsaro.s3.amazonaws.com/"
    const totalElevatorsIcon = publicAssetsBaseUrl + 'Total-Number.svg'
    const totalActiveElevatorsIcon = publicAssetsBaseUrl + 'Active.svg'
    const totalWarningsIcon = publicAssetsBaseUrl + 'Warning.svg'
    const totalAlarmsIcon = publicAssetsBaseUrl + 'Alert.svg'
    const elevatorBlackList = ["4602a587-61a7-440e-b276-7d904b3407bb", "ef90ba88-e9ce-4ef9-8a88-3734d9ad0021", "3f5fbbf5-72e5-488f-87ef-d89e675e2522", "93dea42d-af21-45e7-9b6f-0a277e1b167f", "9288f99f-5aa3-4bf4-9720-24006a94bac1"]


    /**
     * Get the total elevators and total active elevators - July 4th, 2023
     * TODO: Get the most recent floor for the card via lambda call - July 4th, 2023
     * @param {*} listOfProjects 
     */
    const getElevatorTotals = (listOfElevators) => {
        setTotalElevators(listOfElevators.length)
        const activeElevators = listOfElevators.filter(e => e.deviceStatus === 'Active');
        setTotaActiveElevators(activeElevators.length)
    }

    useEffect(() => {
        Auth.currentCredentials()
            .then(credentials => {

                const lambda = new Lambda({
                    credentials: Auth.essentialCredentials(credentials),
                    region: 'us-east-1'
                });

                // defining parameters to invoke function AthenaConnectionForChart-dev
                const params = {
                    FunctionName: 'getElevatorStats',
                    InvocationType: 'RequestResponse',
                    Payload: JSON.stringify({ buildingId: building.id })
                };

                // invoking lambda by passing the defined parameters
                return lambda.invoke(params, function (err, data) {
                    if (err) console.log("ERROR", err, err.stack); // an error occurred
                    else {
                        const payload = JSON.parse(data?.Payload)
                        const res = JSON.parse(payload?.body?.data)

                        //Update the state and the update the dynamodb with the new data
                        let updatedBuildingElevators = building

                        console.log("updatedBuildingElevators", updatedBuildingElevators?.projectId);
                        if (updatedBuildingElevators?.projectId !== "PID_0003") {
                            let tempElevatorInfo = JSON.parse(updatedBuildingElevators?.projectElevatorInfo)
                            res?.Items.forEach(e => {
                                // Elevator id to find in temp array
                                const id = e.deviceId.S

                                const matchIds = (i) => i.elevatingDeviceNo === id
                                const indexOfMatch = tempElevatorInfo.findIndex(matchIds)

                                //Update the current floor in the temp info
                                if (indexOfMatch !== -1) {
                                    // console.log("ELEVATOR: ", e )
                                    tempElevatorInfo[indexOfMatch].current_floor = parseInt(e.floor?.S)
                                    tempElevatorInfo[indexOfMatch].direction = parseInt(e.direction?.S)
                                    tempElevatorInfo[indexOfMatch].trips = e?.trips ? parseInt(e?.trips?.S) : 0
                                    tempElevatorInfo[indexOfMatch].floors = e?.totalFloorsDay ? parseInt(e?.totalFloorsDay?.S) : 0
                                    tempElevatorInfo[indexOfMatch].distance = e?.distanceDay ? parseInt(e?.distanceDay?.S) : 0
                                    tempElevatorInfo[indexOfMatch].tripsWeek = e?.tripsWeek ? parseInt(e?.tripsWeek?.S) : 0
                                    tempElevatorInfo[indexOfMatch].floorsWeek = e?.totalFloorsWeek ? parseInt(e?.totalFloorsWeek?.S) : 0
                                    tempElevatorInfo[indexOfMatch].distanceWeek = e?.distanceWeek ? parseInt(e?.distanceWeek?.S) : 0
                                    let lastUpdatedTemp = e?.lastUpdated ? parseInt(e?.lastUpdated?.S) : 0
                                    lastUpdatedTemp = lastUpdatedTemp !== 0 ? new Date(lastUpdatedTemp).toLocaleString() : 0
                                    tempElevatorInfo[indexOfMatch].lastUpdated = lastUpdatedTemp !== 0 ? lastUpdatedTemp : "N/A"
                                    tempElevatorInfo[indexOfMatch].buildingId = building?.id
                                    tempElevatorInfo[indexOfMatch].floorData = e?.floorDataDay ? e?.floorDataDay?.S : "";
                                    tempElevatorInfo[indexOfMatch].tripsData = e?.tripsDataDay ? e?.tripsDataDay?.S : "";
                                    tempElevatorInfo[indexOfMatch].tripsWeekData = e?.tripsDataWeek ? e?.tripsDataWeek.S : "";
                                    tempElevatorInfo[indexOfMatch].floorWeekData = e?.floorDataWeek ? e?.floorDataWeek.S : "";
                                    tempElevatorInfo[indexOfMatch].rawData = e?.rawData ? e?.rawData.S : "";
                                    let lastChartUpdatedTemp = e?.chartLastUpdated ? parseInt(e?.chartLastUpdated?.S) : 0;
                                    lastChartUpdatedTemp = lastChartUpdatedTemp !== 0 ? new Date(lastChartUpdatedTemp).toLocaleString() : 0
                                    tempElevatorInfo[indexOfMatch].lastChartUpdatedTemp = lastChartUpdatedTemp !== 0 ? lastChartUpdatedTemp : "N/A"
                                    tempElevatorInfo[indexOfMatch].metaDataAvailable = true
                                    tempElevatorInfo[indexOfMatch].projectLocationofElevatingDevice = building?.projectLocationofElevatingDevice ? building?.projectLocationofElevatingDevice : building?.projectId 
                                    //PAL: ADD THE CHART DATA TO THE TEMP ELEVATOR INFO NEXT

                                }


                            });
                            //Set state
                            setElevators(tempElevatorInfo)
                            //Update the building object before sending to ddb
                            updatedBuildingElevators.projectElevatorInfo = JSON.stringify(tempElevatorInfo)
                        } else {
                            let tempElevatorInfo = JSON.parse(updatedBuildingElevators?.projectElevatorInfo)
                            const idsToMatch = ["EID_0003", "EID_0002"];

                            idsToMatch.forEach(id => {
                                const indexOfMatch = tempElevatorInfo.findIndex(i => i.elevatingDeviceNo === id);

                                if (indexOfMatch !== -1) {
                                    tempElevatorInfo[indexOfMatch].metaDataAvailable = true;
                                } else {
                                    // If ID doesn't exist, add it to the array
                                    tempElevatorInfo.push({
                                        elevatingDeviceNo: id,
                                        metaDataAvailable: true
                                    });
                                }
                            });
                            setElevators(tempElevatorInfo)
                            //Update the building object before sending to ddb
                            updatedBuildingElevators.projectElevatorInfo = JSON.stringify(tempElevatorInfo)

                        }


                    }
                })
            })
    }, [building])


    useEffect(() => {
        // console.log("building", building)
        let elevatorInfo = JSON.parse(building?.projectElevatorInfo)
        elevatorInfo = elevatorInfo.filter(elevator => {return !elevatorBlackList.includes(elevator?.elevatingDeviceNo)})

        setElevators(elevatorInfo)
        console.log("building: ", building)
        getElevatorTotals(elevatorInfo)
    }, [building])



    return (
        <>
            <div className="main-container">
              <div className="content-container">    
                <h3 className='dark-title'>{building?.projectLocationofElevatingDevice ? building?.projectLocationofElevatingDevice : building?.projectId}</h3>
                <h5 className='dark-title'> Home / Dashboards / Buildings / <u>{building?.projectLocationofElevatingDevice ? building?.projectLocationofElevatingDevice : building?.projectId}</u></h5>
                <div className="flex-container">
                <StatCard data={totalElevators} img={totalElevatorsIcon} colour={'#3994d4'} title='Total Elevators'/>
                    <StatCard data={totalActiveElevators} img={totalActiveElevatorsIcon} colour={'#20c997'} title='Active Elevators'/>
                    <StatCard data={totalWarnings} img={totalWarningsIcon} colour={'#fd7e14'} title='Warnings'/>
                    <StatCard data={totalAlarms} img={totalAlarmsIcon} colour={'#dc3545'} title='Alerts'/>
                </div>
                <div className="flex-container">
                    {
                        elevators?.map((elevator, index) => {
                            const stats = {mainStat: elevator.current_floor || elevator.current_floor === 0 ? elevator.current_floor : "N/A" , subStats:{projectId: building.projectId, direction: elevator.direction ? elevator.direction : 0, trips: elevator.trips ? elevator.trips : 0}, directon: elevator.direction ? elevator.direction : 0}
                            elevator.dashboardType = building?.projectBM
                            if(!elevatorBlackList.includes(elevator?.elevatingDeviceNo))
                                return <ContentCard key={index} title={elevator?.device_name ? elevator?.device_name : elevator?.elevatingDeviceNo} stats={stats} type={'Elevator'} summaryData={elevator} />
                             else
                                 return<div key={index}></div>
                        })
                    }
                </div>
              </div>
            </div>
        </>
    );
};

export default BuildingDetails;