import React, {
    useState,
    useEffect
  } from 'react';
import { useNavigate } from "react-router-dom";

// Equipment information is being passed from the landing page
function ContentCard({title, subtitle, stats, icon, type, summaryData}){

    const navigate = useNavigate();
    const mainStat = stats.mainStat
    const subStats = stats.subStats

    // const mainStat = useState(stats.mainStat)
    // const subStats = useState(stats.subStats)
    // const [summaryData1, setSummaryData1] = useState(summaryData)

    const publicAssetsBaseUrl = "https://public-assets-tsaro.s3.amazonaws.com/"
    const greenUp = publicAssetsBaseUrl + 'Green-UP.svg'
    const redDown = publicAssetsBaseUrl + 'Red-DOWN.svg'
    const redAlert = publicAssetsBaseUrl + 'Red-ALERT.svg'
    const yellowCaution = publicAssetsBaseUrl + 'Yellow-CAUTION.svg'
    const upArrow = publicAssetsBaseUrl + 'movement-up.svg'
    const downArrow = publicAssetsBaseUrl + 'movement-down.svg'
    const stay = publicAssetsBaseUrl + 'movement-stay.svg'

    const [statusColor, setStatusColor] = useState('#20c997')
    const [currentDirection, setCurrentDirection] = useState('')
    const [statClass, setStatClass] = useState('direction-stat')
    const [directionClass, setDirectionClass] = useState('direction-icon')



    const handleViewDetails = (e, data) => {
        if (type === 'Building') {
          navigate('/building-details', {
            state: JSON.stringify(data)
          });
        }else{
          if (data?.metaDataAvailable){
            
            data.projectId = subStats?.projectId
            navigate('/car-details', {
              state: JSON.stringify([data])
            });
          }else{
            if (data?.dashboardType) {
              data.projectId = subStats?.projectId
              navigate('/car-details', {
                state: JSON.stringify([data])
              });
            }
            console.log("SUMMARY DATA NOT AVAIALBLE: ")
          }
        }
        

      }
    
      useEffect(() => {
        if(summaryData.deviceStatus === 'Active'){
          setStatusColor('#20c997')
        }else{
          setStatusColor('#dc3545')
        }

      },[])

      useEffect(()=>{
        if(type === 'Elevator'){
          if (summaryData.current_floor !== "N/A"){
          
            if(summaryData.direction === 2){
              setCurrentDirection(upArrow)
              setStatClass('direction-stat')
              setDirectionClass('direction-icon')
            }else if(summaryData.direction === 1){
              setCurrentDirection(downArrow)
              setStatClass('direction-stat')
              setDirectionClass('direction-icon')
            }else if (summaryData.direction === 0){
              setCurrentDirection(stay)
              setStatClass('direction-stat')
              setDirectionClass('direction-icon')
            }else{
              setCurrentDirection('')
              setStatClass('direction-stat-no-margin')
              setDirectionClass('direction-icon-hide')
            }
          }else{
            setCurrentDirection('')
            setStatClass('direction-stat-no-margin')
            setDirectionClass('direction-icon-hide')
          }


        }
      },[summaryData])

      return (
        <div className="card">
            <div className='card-header'>
              {type === 'Building' ? 
                <h3 className='card-header-title '>{title}</h3>
                :
                <h3 className='card-header-title card-header-centered-vert'>{title}</h3>
              }
                <h5 className='card-header-subtitle'>{subtitle}</h5>
            </div>
            <div className="card-container  bottom-margin-card">
                {type === 'Elevator' ? 
                <>
                  <div className='direction-icon-container'>
                    <img className={directionClass} src={currentDirection} alt="direction icon"/>
                    <h1 className={statClass}>{mainStat}</h1>
                  </div>
                  <div className='car-card-stats'>
                    <div>Trips (24hrs): <strong>{summaryData?.trips ? summaryData?.trips : 0}</strong></div>
                    {/* <div>Distance Travelled by <br/> the Elevator[ft]: <strong>{summaryData?.distance ? summaryData?.distance : 0}</strong></div>
                     */}
                     <div>Door Cycles (24hrs) <strong>N/A</strong></div>
                    <div>Floors Travelled: <strong>{summaryData?.floors ? summaryData?.floors : 0}</strong></div>
                  </div>
                  <button className='card-btn-outline' onClick={(e)=>{handleViewDetails(e, summaryData)}}>{'VIEW DETAILS'}</button>
                </>
                :
                <>
                  <h1 className='content-card-stat'>{mainStat}</h1>
                  <button className='card-btn-outline' onClick={(e)=>{handleViewDetails(e, summaryData)}}>{'VIEW DETAILS'}</button>
                </>
                }

            </div>  

            <div className='card-footer'>
              {type === 'Building' &&
                <>
                  <div className='content-card-icon-container-parent'>
                    <div className='sm-stat'>{subStats.active}</div>
                    <div className='content-card-icon-container'>
                      <img className='sm-footer-icon' src={greenUp} alt="icon"/>
                    </div>
                  </div>
                  <div className='content-card-icon-container-parent'>
                    <div className='sm-stat'>{subStats.inactive}</div>
                    <div className='content-card-icon-container'>
                      <img className='sm-footer-icon' src={redDown} alt="icon"/>
                    </div>
                  </div>
                  <div className='content-card-icon-container-parent'>
                    <div className='sm-stat'>{subStats.caution}</div>
                    <div className='content-card-icon-container'>
                      <img className='sm-footer-icon' src={yellowCaution} alt="icon"/>
                    </div>
                  </div>
                  <div className='content-card-icon-container-parent'>
                    <div className='sm-stat'>{subStats.warning}</div>
                    <div className='content-card-icon-container'>
                      <img className='sm-footer-icon' src={redAlert} alt="icon"/>
                    </div>
                  </div>
                </>
              }

              {type === 'Elevator' && 
              <>
                <div className='status-icon' style={{backgroundColor: statusColor}}>{summaryData.deviceStatus}</div>
              </>
              }
            </div>
        </div>
        );
}

export default ContentCard;